import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DefaultComponent} from './layouts/default/default.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component'
import {DEFAULT_LAYOUT_ROUTES} from './routes/default-layout.routes';
import {DASHBOARD_LAYOUT_ROUTES} from './routes/dashboard-layout.route';
import {ACCOUNT_ROUTES} from "./routes/account.routes";
import {PROJECT_ROUTES} from "./routes/project.routes" ;
import {DAR_ROUTES} from "./routes/dar.routes";
import {USER_ROUTER} from './routes/user.routes';
import {PROFILE_ROUTES} from './routes/profile.routes';
import {REPORTS_ROUTES} from './routes/reports.routes';
import {AuthGuard} from './auth/auth.guard';
import { LEAVE_ROUTES } from './routes/leave.routes';
const routes:Routes=[
        {path:"",component:DefaultComponent, children:DEFAULT_LAYOUT_ROUTES},
        {path:"dashboard",component:MainLayoutComponent, canActivate: [AuthGuard],children:DASHBOARD_LAYOUT_ROUTES},
        {path:"user",component:MainLayoutComponent,canActivate: [AuthGuard],children:USER_ROUTER},
        {path:"leave-manager",component:MainLayoutComponent,canActivate: [AuthGuard],children:LEAVE_ROUTES},
        {path:"account",component:MainLayoutComponent,canActivate: [AuthGuard],children:ACCOUNT_ROUTES},
        {path:"project",component:MainLayoutComponent,canActivate: [AuthGuard],children:PROJECT_ROUTES},
        {path:"dar-management",component:MainLayoutComponent,canActivate: [AuthGuard],children:DAR_ROUTES},
        {path:"profile",component:MainLayoutComponent,canActivate: [AuthGuard],children:PROFILE_ROUTES},
        {path:"report",component:MainLayoutComponent,canActivate: [AuthGuard],children:REPORTS_ROUTES}

        
]

@NgModule({
    imports:[RouterModule.forRoot(routes)],
    exports:[RouterModule]
})

export class AppRoutingModule{}
