import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {environment} from './../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private _http:HttpClient) { }
 // baseUrl='http://staging1.delivery-projects.com/dar-app-api/api/v1/user';

  registerUser(user){
     return this._http.post(`${environment.apiBaseUrl}/user/register`,{user:user}); 
  }
  login(user){
     return this._http.post(`${environment.apiBaseUrl}/user/login`,{user})
  }
  isLogin(){
    if(localStorage.getItem("currentUser")){
        return true;
    }
    return false;
  }
  getLoggedInUser() {
    
    const loginUser = JSON.parse(localStorage.getItem("currentUser"));
    //console.log(loginUser);
    return loginUser;
  }
  isEmailExist(email){
     return this._http.get(`${environment.apiBaseUrl}/user/check-email/?email=${email}`);
  }
  isPasswordEixst(password,emp_id){
    return this._http.get(`${environment.apiBaseUrl}/user/check-password/?password=${password}&emp_id=${emp_id}`)
  }
  updatePassword(payload){
    return this._http.put(`${environment.apiBaseUrl}/user/change-password`,{payload:payload})
  }
  getDepartment(){
      return this._http.get(`${environment.apiBaseUrl}/user/department`);
  }
  getDesignation(deptID){
    return this._http.get(`${environment.apiBaseUrl}/user/designation/${deptID}`)
  }
  forgetPassword(email){
     return this._http.post(`${environment.apiBaseUrl}/user/forget-password`,{email:email})
  }
  resetPassword(payload){
    return this._http.put(`${environment.apiBaseUrl}/user/reset-password`,{payload:payload})
  }
}
