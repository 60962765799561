import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthServiceService} from './auth-service.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _authService:AuthServiceService,private router:Router){}
  canActivate(): boolean{
   // console.log(this._authService.isLogin())
      if(this._authService.isLogin()){
        return true;   
      }else{
        this.router.navigate(['/login']);
      }
  }
  
}
